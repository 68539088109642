/* eslint-disable */
import React, { Component } from "react";
// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Privacy Policy" />
          <section className="normal-section content-page light-nav brown-nav">
            <div className="container">
              <div className="content-contain">
                <h1>Privacy Policy</h1>
                <p>
                  At Koji Capital we are committed to providing professional
                  services and respecting the privacy of your personal
                  information. We are bound by the Australian Privacy Principles
                  under the Privacy Act 2001. This Privacy Policy explains how
                  we will collect, store, verify, use and disclose the
                  information we hold about you and the conditions under which
                  your information may be accessed.
                </p>
                <p>
                  <b>Changes to our privacy policy</b>
                </p>
                <p>
                  If at any time our Privacy Policy changes, the updated details
                  will be available on our website (koji.capital). Any
                  information we hold about you will be governed by the most
                  current version of the privacy policy. Your continued use of
                  our site, products and services indicates your acceptance of
                  any changes.
                </p>
                <p>
                  <b>Collection of personal information</b>
                </p>
                <p>
                  Koji Capital collects and maintains personal information that
                  is necessary to enable us to deliver our services or products
                  for our primary business activities. In the normal course of
                  our business, we only collect information about you when you
                  provide it to us or it is provided to us under your authority.
                  We will collect personal information directly from you when
                  you apply for a product or a service which we are obliged to
                  do by law prior to providing you with a product or service. On
                  occasion we may collect personal information about you from
                  third parties, for example credit reporting agencies, share
                  registries or regulatory authorities. Information that we
                  collect will be limited to that required to provide you with
                  our services.
                </p>
                <p>
                  <b>What type of information do we hold?</b>
                </p>
                <p>
                  The type of information we collect and hold about you varies
                  depending on the type of product or service you require.
                </p>
                <p>The personal information we hold about you may include:</p>
                <ul>
                  <li>
                    Your personal details e.g. name, address, bank account
                    details, TFN details etc; and
                  </li>
                  <li>
                    Any other relevant information that you provide to us to
                    enable us to provide you with a product or service.
                  </li>
                </ul>
                <p>
                  We will only collect, maintain and use your personal
                  information to adequately provide the products or services you
                  have requested. In most circumstances, the information that we
                  hold about you has been provided to us by you or under your
                  authority. If we have received information that is not
                  required in order to provide services to you, we will either
                  advise you that we hold this information or destroy it.
                </p>
                <p>
                  Other purposes for which we may need to collect and use your
                  personal information will include:
                </p>
                <ul>
                  <li>
                    To comply with legislative and regulatory requirements
                    (including the AML/CTF 2006 Act);
                  </li>
                  <li>
                    To enable us to perform administrative operations such as
                    accounting, record keeping and archival retrieval; and
                  </li>
                  <li>
                    To enable us to contact you when conducting marketing.
                  </li>
                </ul>
                <p>
                  You may choose not to provide us with your personal
                  information. In this case we may not be able to deal with you
                  including providing a financial product. Under the AML/CTF
                  laws we must be able to identify our clients and verify that
                  identity.
                </p>
                <p>
                  <b>How we use your information</b>
                </p>
                <p>
                  Where required, we collect, use and exchange your information
                  so that we can:
                </p>
                <ul>
                  <li>
                    Establish your identity, assess your eligibility and
                    suitability for our products and services, and respond to
                    your queries;
                  </li>
                  <li>
                    Make improvements and set the price and design for our
                    products, services and marketing;
                  </li>
                  <li>Administer our products and services;</li>
                  <li>
                    Manage our relationship with you and keep you updated on
                    important information, products and services that might
                    interest you;
                  </li>
                  <li>
                    Manage our risks and prevent or investigate any actual or
                    suspected fraud, unlawful activity or misconduct;{" "}
                  </li>
                  <li>Comply with our legal obligations; and</li>
                  <li>
                    We may also collect, use and exchange your information in
                    other ways where permitted by law.
                  </li>
                </ul>
                <p>
                  <b>Direct marketing</b>
                </p>
                <p>
                  Unless you direct us otherwise, we may use your personal
                  information for direct marketing, and may share it with third
                  party provider companies for the sole purpose of facilitating
                  direct marketing. If you do not want to receive direct
                  marketing, please tell us by emailing
                  <a href="mailto:investorservices@noncorrelatedcapital.com.au">
                    investorservices@noncorrelatedcapital.com.au
                  </a>
                  .
                </p>
                <p>
                  <b>Sharing your information</b>
                </p>
                <p>
                  Staff members at Koji Capital will be able to access the
                  information you share with us, but only when they need it to
                  provide you with a product or service.
                </p>
                <p>
                  We never share your personally identifiable information with
                  advertisers without your express permission. If you choose to
                  use the goods and services advertised, disclose your personal
                  information directly to those companies or grant them
                  permission to collect information about you, then their use of
                  your information is governed by their privacy policies.
                </p>
                <p>
                  Direct marketing Unless you direct us otherwise, we may use
                  your personal information for direct marketing, and may share
                  it with third party provider companies for the sole purpose of
                  facilitating direct marketing. If you do not want to receive
                  direct marketing, please tell us by emailing
                  <a href="mailto:investorservices@noncorrelatedcapital.com.au">
                    investorservices@noncorrelatedcapital.com.au
                  </a>
                  . Sharing your information Staff members at Koji Capital will
                  be able to access the information you share with us, but only
                  when they need it to provide you with a product or service. We
                  never share your personally identifiable information with
                  advertisers without your express permission. If you choose to
                  use the goods and services advertised, disclose your personal
                  information directly to those companies or grant them
                  permission to collect information about you, then their use of
                  your information is governed by their privacy policies. There
                  are circumstances under which the Company may disclose your
                  personal information such as:
                </p>
                <ul>
                  <li>
                    When the disclosure is required by law (for example,
                    disclosed to the Australian Taxation Office, AUSTRAC or
                    Centrelink;
                  </li>
                  <li>
                    Authorised by law (such as where disclosure is necessary in
                    the public interest or to protect our interests;
                  </li>
                  <li>
                    In the event that we propose to sell our business, we may
                    disclose your personal information to potential purchasers
                    for the purpose of them conducting due diligence
                    investigations. In the event that a sale of our business
                    occurs, we may transfer your personal details to the
                    purchaser of the business. As a client, you will be advised
                    of such a transfer; and
                  </li>
                  <li>
                    Responding to subpoenas, court orders and other legal
                    processes.
                  </li>
                </ul>
                <p>
                  Where it is necessary to share information about you with and
                  between our affiliates and organisations that provide services
                  to us. You agree that we may provide your personal information
                  to these service provides to use in connection with the supply
                  of services to you and that they may provide us with your
                  personal information to enable us to provide services to you.
                </p>
                <p>
                  We may disclose personal information outside of the
                  jurisdiction of Australia including the United States of
                  America, European countries and Asian countries. The most
                  common reason will be when we use service providers to perform
                  some functions on our behalf.
                </p>
                <p>
                  The privacy laws of those countries may not provide the same
                  level of protection as the privacy laws of Australia. However,
                  this does not change our commitment and obligation to
                  safeguard your privacy and we will comply with all applicable
                  laws relating to cross-border data disclosure.
                </p>
                <p>
                  <b>Website links</b>
                </p>
                <p>
                  Our website may contain links to third party websites to which
                  we have no affiliation. Unless you give us permission, we will
                  not share your personal information with third party websites.
                  These websites have different privacy policies, and we
                  recommend you read them before utilising the websites and
                  third-party services.
                </p>
                <p>
                  <b>Keeping your information secure</b>
                </p>
                <p>
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorised access or disclosure, we have
                  put in place physical, electronic and managerial procedures to
                  safeguard and secure the information we collect online.
                </p>
                <p>
                  We may store your personal information in hardcopy documents
                  or electronically. Both methods have locks, security systems
                  and network security, and our staff policies require our staff
                  to respect the confidentiality of any personal information
                  held by us.
                </p>
                <p>
                  Our electronic storage is on our own server and secure
                  cloud-based servers of third party companies. We will ensure
                  any overseas third parties have appropriate data handling and
                  security arrangements in place.
                </p>
                <p>
                  While we do everything we can to protect your information, we
                  cannot assure you that personally identifiable information
                  will never be disclosed in a manner that is inconsistent with
                  this Privacy Policy. By using our services and products, you
                  acknowledge that we are not responsible for any intercepted
                  information sent via the Internet, and you hereby release us
                  form any and all claims arising out of or related to the use
                  of intercepted information in any unauthorised manner.
                </p>
                <p>
                  In the event there is a significant privacy breach, at law we
                  are required to notify you and also report the breach to the
                  Privacy Commissioner.
                </p>
                <p>
                  In the event you cease to be a client of the Company, any
                  personal information which we hold about you will be
                  maintained in a secure manner for a period of seven years in
                  order to comply with legislative and professional
                  requirements, following which time the information will be
                  destroyed.{" "}
                </p>
                <p>
                  <b>Accessing your information</b>
                </p>
                <p>
                  You have a right to contact us to access the personal
                  information we hold about you. Where you are entitled to
                  access, the time we require to give you access will depend on
                  the type of information requested. Generally, we will make it
                  available to you within 30 days. We don’t charge a fee,
                  however if your request is complex we may pass on an access
                  charge for the time we spend locating, compiling and
                  explaining the information you ask for. If this is the case,
                  we will give you an estimate upfront and confirm it with you
                  before we proceed.
                </p>
                <p>
                  In certain circumstances we are allowed to deny your request,
                  or limit the access we provide. For example we might not
                  provide you access to commercially sensitive information or
                  information that may unreasonably impact upon another person’s
                  right to privacy. Whatever the outcome, we will write to you
                  explaining our decision.
                </p>
                <p>
                  We may also ask you to identify yourself to our satisfaction.
                </p>
                <p>
                  We will also try to answer you in the same way that you ask,
                  for example, if you telephone to ask for the information, we
                  will if practicable, give you that information over the
                  telephone. We will generally respond to a written request in
                  writing.
                </p>
                <p>
                  Sometimes we will ask that you put your request in writing,
                  for example, where you want copies of material or access to
                  older information or files which are not current or it is
                  necessary for us to retain record of your request.
                </p>
                <p>
                  You may request access to your personal information by using
                  our contact details below.
                </p>
                <p>
                  <b>Updating and correcting your information</b>
                </p>
                <p>
                  If you believe that the personal information we hold about you
                  is inaccurate, incomplete or out-of-date, you have a right to
                  update or correct your information at any time by contacting
                  us.
                </p>
                <p>
                  If we disagree with you that the information should be
                  corrected, we will let you know in writing. You can ask us to
                  include a statement with the relevant information, indicating
                  your view that the information is inaccurate, misleading,
                  incomplete, irrelevant or out-of-date. We will take reasonable
                  steps to comply with such a request.
                </p>
                <p>
                  Your request should be made by phone, email or mail to the
                  Director of the Licensee, using the contact details below.
                </p>
                <p>
                  <a
                    href="https://www.noncorrelatedcapital.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.noncorrelatedcapital.com
                  </a>
                </p>
                <p>
                  Koji Capital Office
                  <br />
                  L1 172 Hume Street, East Toowoomba QLD 4350
                </p>
                <p>
                  <b>Closing your account</b>
                </p>
                <p>
                  Please note that when we close your account, we may retain and
                  use your information as necessary to comply with our legal
                  obligations, resolve disputes and enforce our agreements.
                  There may be latency in deleting information from our servers
                  and back-up versions might exist after deletion.
                </p>
                <p>
                  <b>Making a privacy complaint</b>
                </p>
                <p>
                  If you have a concern about your privacy, you have a right to
                  make a complaint and we will do everything we can to put
                  matters right.
                </p>
                <p>
                  To lodge a complaint, please contact us at the details listed
                  above. Within 7 days we will contact you and assign you a
                  contact person within the licensee. We will review your
                  situation and try to resolve it straight away. We keep you
                  updated on the progress we’re making towards fixing the
                  problem.
                </p>
                <p>
                  Usually, it takes only a few days to resolve a complaint.
                  However, if we are unable to provide a final response within
                  45 days, we’ll contact you to explain why and discuss a
                  timeframe to resolve the complaint.
                </p>
                <p>
                  If you raised the matter with us and it has not been resolved
                  to your satisfaction, you can refer your complaint to the
                  Office of the Australian Information Commissioner. We suggest
                  you do this only once you have followed our internal complaint
                  processes set out above.
                </p>
                <p>
                  You may contact the Office of the Australian Information
                  Commissioner by:
                </p>
                <p>
                  1300 363 992, online at www.oaic.gov.au or via post at GPO Box
                  5218 Sydney NSW 2001.
                </p>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

export default PrivacyPolicy;
